<template lang="html">
  <v-container pa-0 fluid>
    <v-row dense>
      <v-col cols="12">
        <v-model-table model-name="DocumentsTemplates" :model-api="['models','Document','DocumentsTemplates']" 
          model-key="templateCode"
          :headers="headers" :initial-data="initialData" 
          searchable importable exportable serverPagination>
          <template v-slot:modelForm="{data,rules,isCreating}">
            <v-container pa-0 fluid>
              <v-row>
                <v-col cols="12" md="3">
                  <v-text-field :rules="[rules.require()]" v-model="data.templateCode" label="Template ID" :disabled="!isCreating"></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field :rules="[rules.require()]" v-model="data.revisionId" label="Revision ID"></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-date-field :rules="[rules.require()]" v-model="data.revisionDate" label="Revision Date"></v-date-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field v-model="data.templateCompatibility" label="Compatibility Code"></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field :rules="[rules.require()]" v-model="data.templateName" label="Template Name"></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="data.description" label="Description"></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-master-field :rules="[rules.require()]" v-model="data.defaultCategory" label="Default Category"
                    groupKey="documentCategory"
                    show-code
                    clearable
                  ></v-master-field>
                </v-col>
                <v-col cols="12" md="2">
                  <v-checkbox v-model="data.isRequiredPatientInfo" label="Required Patient Info"></v-checkbox>
                </v-col>
                <v-col cols="12" md="2">
                  <v-checkbox v-model="data.isRequiredEncounter" label="Required Encounter"></v-checkbox>
                </v-col>
                <v-col cols="12" md="1">
                  <v-checkbox v-model="data.isPrintable" label="Printable"></v-checkbox>
                </v-col>

                <v-col cols="12" md="2" v-if="data.isPrintable">
                  <v-checkbox v-model="data.isNoDefaultHeader" label="Use custom header"></v-checkbox>
                </v-col>
                <v-col cols="12" md="2" v-if="data.isPrintable">
                  <v-checkbox v-model="data.isNoDefaultFooter" label="Use custom footer"></v-checkbox>
                </v-col>
                <v-col cols="12" md="3" v-if="data.isPrintable" align-self="center">
                  <v-file-field v-model="data.printTemplate" required>Print Template File</v-file-field>
                </v-col>
                <v-col cols="12">
                  <v-tabs-group>
                    <template v-slot:tabs>
                      <v-tab key="edit">Edit Template</v-tab>
                      <v-tab key="view">Preview</v-tab>
                    </template>
                    <template v-slot:items>
                      <v-tab-item key="edit">
                        <v-code-editor v-model="data.editTemplate"></v-code-editor>
                      </v-tab-item>
                      <v-tab-item key="view">
                        <v-card outlined tile>
                          <v-form-pad :template="data.editTemplate" :template-script="data.templateScript" preview></v-form-pad>
                        </v-card>
                      </v-tab-item>
                    </template>
                  </v-tabs-group>
                </v-col>
                <v-col cols="12">
                  <v-tabs-group>
                    <template v-slot:tabs>
                      <v-tab key="edit">View Template</v-tab>
                      <v-tab key="view">Preview</v-tab>
                    </template>
                    <template v-slot:items>
                      <v-tab-item key="edit">
                        <v-code-editor v-model="data.viewTemplate"></v-code-editor>
                      </v-tab-item>
                      <v-tab-item key="view">
                        <v-card outlined tile>
                          <v-form-pad :template="data.viewTemplate" :template-script="data.templateScript" preview></v-form-pad>
                        </v-card>
                      </v-tab-item>
                    </template>
                  </v-tabs-group>
                </v-col>
                <v-col cols="12">
                  <v-tabs-group>
                    <template v-slot:tabs>
                      <v-tab key="script">Script</v-tab>
                    </template>
                    <template v-slot:items>
                      <v-tab-item key="script">
                        <v-code-editor v-model="data.templateScript" lang="js"></v-code-editor>
                      </v-tab-item>
                    </template>
                  </v-tabs-group>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-slot:item.templateCode="props">
            {{ props.item.templateCode }} (REV {{ props.item.revisionId }})
          </template>
          <template v-slot:item.defaultCategory="props">
            <v-label-master group-key="documentCategory" :item-code="props.item.defaultCategory"></v-label-master>
          </template>
          <template v-slot:item.isRequiredPatientInfo="props">
            <v-icon>{{ (props.item.isRequiredPatientInfo) ? 'check' : 'close' }}</v-icon>
          </template>
          <template v-slot:item.isRequiredEncounter="props">
            <v-icon>{{ (props.item.isRequiredEncounter) ? 'check' : 'close' }}</v-icon>
          </template>
          <template v-slot:item.isPrintable="props">
            <v-icon>{{ (props.item.isPrintable) ? 'check' : 'close' }}</v-icon>
          </template>
        </v-model-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: ()=>({
    headers: [
      {
        text: 'Template Code',
        value: 'templateCode',
        class: 'body-2'
      },
      {
        text: 'Template Name',
        value: 'templateName',
        class: 'body-2'
      },
      {
        text: 'Default Category',
        value: 'defaultCategory',
        class: 'body-2'
      },
      {
        text: 'With HN',
        value: 'isRequiredPatientInfo',
        class: 'body-2'
      },
      {
        text: 'With Encounter',
        value: 'isRequiredEncounter',
        class: 'body-2'
      },

      {
        text: 'Printable',
        value: 'isPrintable',
        class: 'body-2'
      },
      {
        text: 'Action',
        value: 'action',
        align: 'center',
        width: '150px',
        sortable: false,
        class: 'body-2'
      }
    ],
    initialData: {
      isRequiredPatientInfo: true,
      isRequiredEncounter: true,
      isPrintable: true,
    }
  }),
}
</script>

<style lang="css">
</style>
